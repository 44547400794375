.search-side-btn {
background-color: #F1f1f1;
  color: black;
  width: 30px;
  position: absolute;
  right: 0px;
  top: -11px;
  /* height: fit-content !important; */

  border-radius: 0 15px 15px 0;
  font-size: 14px;
}