.afcss_container {
  border: 1px solid #ced4da;
}

.afcss_borderShow {
  border: none;
}

.afcss_basic_hideA_3 {
  width: 33.33%;
}

.afcss_basic_hideA_2 {
  width: 50%;
}

.afcss_basic_hideA_1 {
  width: 100%;
}

.afcss_basic_nohideA {
  width: 20%;
}

.afcss_main_4 {
  width: 40%;
}

.afcss_main_3 {
  width: 60%;
}

.afcss_main_2 {
  width: 80%;
}

.afcss_main_1 {
  width: 100%;
}

@media screen and (max-width: 990px) {
  .afcss_basic_nohideA_mid_3 {
    width: 33.33%;
  }

  .afcss_basic_nohideA_mid_2 {
    width: 50%;
  }

  .afcss_basic_nohideA_mid_1 {
    width: 100%;
  }

  .afcss_basic_hideA_3 {
    width: 33.33%;
  }

  .afcss_basic_hideA_2 {
    width: 50%;
  }

  .afcss_basic_hideA_1 {
    width: 100%;
  }

  .afcss_main_4 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_main_3 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_main_2 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_main_1 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_container {
    border: none;
  }

  .afcss_borderShow {
    border: 1px solid #ced4da;
  }
}

@media screen and (max-width: 575px) {
  .afcss_basic_nohideA_mid_3 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_basic_nohideA_mid_2 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_basic_nohideA_mid_1 {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_masic_nohideA {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_masic_nohideA {
    width: 100%;
    margin-top: 10px;
  }

  .afcss_basic_hideA_3 {
    width: 100%;
  }

  .afcss_basic_hideA_2 {
    width: 100%;
  }

  .afcss_basic_hideA_1 {
    width: 100%;
  }

  .afcss_special_ {
    width: 50%;
  }
}

.autosuggestWrapper #addressLine-searchField #addressLine-input {
  border: 1px solid #CCC !important;
  border-radius: 0px 15px 15px 0px !important;
  font-size: 12px !important;
  padding: 4px !important;
  height: 36px !important;
  flex: 1;
  width: 100% !important;
}

.auto-suggestions {
  font-size: 12px !important;
  background-color: #FFFFFF !important;
  color: #333 !important;
}

.auto-suggestions .highlighted {
  background-color: #eeecec !important;
  color: #333 !important;
}


.autosuggestWrapper #addressLine-searchField #addressLine-label {
  display: none !important;
}

.autosuggestWrapper {
  width: 66% !important;
}


.autosuggestWrapper div:not(.autocompleteSuggestions) {
  width: 100% !important;
}

.autosuggestWrapper .autocompleteSuggestions {
  width: 66% !important;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  max-height: 36px !important;
  min-height: 36px !important;
  border-radius: 15px 0px 0px 15px !important;
  border: 1px solid #CCC !important;
  box-shadow: none !important;
}