.btn-continue{
    margin-bottom: 20px;
    text-align: end;
}

.heading-name{
    margin-bottom: 0px;
    width: 100%;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif; 

    
}
.heading-name.selected {
    color: #F08180;
}
.main-heading{
    font-weight: bold;
    font-size: 18px;
    margin-top: 5px;

}

.data-profile{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:18px;
}
.image-profile{
    max-width: 100px;
    max-height: 50px;
    width: 30px;
    height: 30px;
    margin-right: 30px;
}

.optionWrapper{
    cursor: pointer;
    border: 1px solid #C4C4C4;
    width: 100%;
    min-width: 350px;
    padding: 8px 20px;
    background-color: #FFFFFF;
    margin: 6px 0px;
    border-radius: 10px;
    

}
.optionWrapper.selected {
    border: 1px solid #F08180;
    color: #F08180;
}














