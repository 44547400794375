.main-layout {
    margin-left: 280px;

}


.main-layout-collapsed {
    margin-left: 70px;
    padding: 10px;
}

#page-topbar-expanded {
    margin-left: 70px;
    z-index: 15;
}


@keyframes slide1 {
    0% {
        left: 0px;
        width: 0vw;
        z-index: 0;
    }

    100% {
        left: -1200px;
        width: 1200px;
        z-index: 1000;
    }
}

@keyframes slide2 {
    0% {
        left: 0px;
        width: 0vw;
        z-index: 0;
    }

    100% {
        left: calc(-100vw + 50px);
        min-width: calc(100vw - 60px);
        z-index: 1000;
    }
}

@keyframes slide4 {
    0% {
        left: -1200px;
        width: 1200px;
        z-index: 0;
    }

    100% {
        left: 50px;
        width: 0px;
        z-index: 0;
    }
}

.map-card {
    height: calc(100vh - 75px);
    position: absolute;
    max-width: 1200px;
    top: 75px;
    box-shadow: -10px 2px 10px rgba(112, 112, 112, 0.2);
    background-color: white;
    border-radius: 20px 0px 0px 20px;
    padding: 20px;
    -webkit-animation: slide1 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide1 0.5s forwards;
    animation-delay: 0s;
}

@media (max-width: 1040px) {
    .map-card {
        min-height: 91vh;
        -webkit-animation: slide2 0.5s forwards;
        -webkit-animation-delay: 0s;
        animation: slide2 0.5s forwards;
        animation-delay: 0s;
    }
}

.map-card-hide {
    position: absolute;
    left: -40.2vw;
    box-shadow: 0px 0px 0px rgba(112, 112, 112, 0.0);
    -webkit-animation: slide4 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide4 0.5s forwards;
    animation-delay: 0s;
}

.bird-eye-container {
    position: relative;
}



.flyout-container-vehicle {
    position: absolute;
    z-index: 1;
}

.sidebar--open {
    width: 280px;
    margin-top: px;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.sidebar--collapse {
    width: 70px;
    margin-top: 30px;
    height: calc(100vh - 70px);
    overflow-y: auto;
}

.nav-side-active {
    background-color: #1678AE;
    color: #ffffff !important;
    font-weight: bold;
    border-radius: 10px;
}

.nav-sub-side-active {
    background-color: #1678AE !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border-radius: 10px !important;
}



.nav-sub-side {
    background-color: #FFFFFF;
    font-weight: bold;
    border-radius: 10px;
}


.nav-sub-side:not(.nav-sub-side-active) :hover {
    background-color: #F1FAFC;
    font-weight: bold;
    border-radius: 10px;
    /* New background color on hover */
}

/* .nav-sub-side-active:hover {
    background-color: #1678AE !important;
    color: #ffffff !important;
    font-weight: bold !important;
    border-radius: 10px !important;
} */