 .fv_ondato_info-pair {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 10px 0;
     border-bottom: 1px solid #eee;
 }

 .fv_ondato_label {
     font-weight: bold;
     color: #666;
     min-width: 160px;
 }

 .fv_ondato_value {
     font-weight: normal;
     color: #333;
     text-align: right;
 }

  .fv_ondato_value-column {
      font-weight: normal;
      color: #333;
  }

 .fv_ondato_fraud-check {
     padding-top: 20px;
 }

 .fv_ondato_rule {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 0.5rem 0;
 }

 .fv_ondato_rule-name {
     flex-grow: 1;
     margin-left: 0.5rem;
 }


 .fv_ondato_skeleton {
     background-color: #eee;
     border-radius: 4px;
     height: 1rem;
     margin: 0.2rem 0;
     animation: shimmer 1.5s infinite;
 }

 .spinner {
     animation: spin 1s linear infinite;
 }

 @keyframes spin {
     100% {
         transform: rotate(360deg);
     }
 }

 @keyframes shimmer {
     0% {
         background-color: #efefef;
     }

     50% {
         background-color: #dfdfdf;
     }

     100% {
         background-color: #efefef;
     }
 }


 @keyframes shimmerImg {
     0% {
         background-position: -150px 0;
     }

     100% {
         background-position: 150px 0;
     }
 }

 .image-skeleton {
     width: 100%;
     height: 100px;
     margin-top: 20px;
     background-color: #eee;
     background-image: linear-gradient(to right, #eee 0%, #ddd 50%, #eee 100%);
     background-repeat: no-repeat;
     background-size: 150px 100%;
     /* the size of the gradient */
     animation: shimmerImg 1.5s infinite linear;
     border-radius: 4px;
 }