.fv_business_intro_container {
    background: #ffffffe6;
    border-radius: 15px;
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



/********************************************************************************
 * Desktop Comparison Section
 ********************************************************************************/
.fv_business_intro_desktop_comparison {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 30px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    max-width: 1000px;
    width: 90%;
    margin: 2rem auto;
    padding: 0rem;
    font-size: 0.95rem;
    color: #1a202c;
}

.fv_business_intro_desktop_row_header {
    background-color: #f5f5f5;
    font-weight: 600;
    font-size: 1.25rem;
    border-radius: 15px 15px 0 0;
    padding: 1rem 0rem;
}

.fv_business_intro_row,
.fv_business_intro_row_common {
    font-size: 0.95rem;
    border-bottom: 1px solid #e5e5e5;
}

.fv_business_intro_row.last {
    border-bottom: 0px solid #e5e5e5;
}

.fv_business_intro_col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    padding-left: 1rem !important;
}

.fv_business_intro_col.right {
    border-left: 1px solid #e5e5e5;
    padding-left: 1rem !important;
}
/********************************************************************************
 * Mobile Layout
 ********************************************************************************/
.fv_business_intro_mobile_layout {
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
    position: relative;
}

/* Mobile card style (similar to .fv_indi-container) */
.fv_business_intro_mobile_card {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2rem;
    margin-bottom: 1.5rem;
}

/* Card title in mobile */
.fv_business_intro_card_title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #1a202c;
    background-color: #f5f5f5;
    font-weight: 600;
    padding: 1rem;
}

/* Mobile item text */
.fv_business_intro_mobile_item {
    border-bottom : 1px solid #ccc;
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.75rem;
    background-color: #fff;
    padding: 10px;
}

/********************************************************************************
 * Example Additional Components
 ********************************************************************************/
.fv_start_screen-button {
    background-color: #3a8dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;
}

.fv_start_screen-button:hover {
    background-color: #1c6fd9;
    transform: translateY(-2px);
}

.box-tab {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(198, 243, 255, 0.331);
}

.bold-text {
    font-weight: bold;
}

.fvi_text_area {
    background-color: #F1FAFC;
    border-radius: 6px;
    border: none;
}

.fvi_text_area:hover,
.fvi_text_area:focus {
    background-color: #F1FAFC;
}

.fvi_text_area:disabled {
    background-color: #F1FAFC;
    color: rgb(69, 69, 69);
}