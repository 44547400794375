.user-avatar-circle {
    width: 30px;
    height: 30px;
    background-color: #ddd; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-weight: bold;
    font-size: 14px; 
  }