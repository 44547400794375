.fv_ubo_container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 20px;
    max-width: 900px;
    margin: auto;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    min-height: 100vh;
    /* Ensure it takes full height of the viewport */
}

.fv_ubo_title {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    color: #333;
}

.fv_ubo_subtitle {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    color: #666;
}

.fv_ubo_details {
    background: #fff;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.fv_ubo_detail {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.fv_ubo_label {
    font-weight: bold;
    color: #333;
}

.fv_ubo_value {
    color: #555;
}

.fv_ubo_error {
    color: red;
    text-align: center;
    margin-top: 20px;
}

.fv_ubo_skeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.fv_ubo_skeleton-header {
    height: 32px;
    background: #ccc;
    border-radius: 5px;
    animation: fv_ubo_shimmer 1.5s infinite;
}

.fv_ubo_skeleton-subtitle {
    height: 20px;
    background: #ccc;
    border-radius: 5px;
    animation: fv_ubo_shimmer 1.5s infinite;
    margin-top: 10px;
    margin-bottom: 20px;
}

.fv_ubo_skeleton-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fv_ubo_skeleton-detail {
    height: 20px;
    background: #ccc;
    border-radius: 5px;
    animation: fv_ubo_shimmer 1.5s infinite;
}

.fv_ubo_skeleton-detail.short {
    width: 90%;
}

.fv_ubo_skeleton-button {
    height: 40px;
    background: #ccc;
    border-radius: 5px;
    animation: fv_ubo_shimmer 1.5s infinite;
    margin-top: 20px;
}

@keyframes fv_ubo_shimmer {
    0% {
        background-position: -200px 0;
    }

    100% {
        background-position: 200px 0;
    }
}

.fv_ubo_button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
}

.fv_ubo_button:hover {
    background: #0056b3;
}