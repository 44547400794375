.section-border {
  border: 1px solid #ced4da;
  position: relative;
  padding: 12px 15px 15px 15px;
  /* padding-top: 25px; */
  margin-top: 10px !important;
  border-radius: 6px;
}

.section-title {
  position: absolute;
  top: -10px;
  padding: 0 5px 0 5px;
  background-color: white;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
}
