.fv_ondato_success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(to bottom right, #f0f4f8, #d9e2ec);
    text-align: center;
    padding: 2rem;
}

.fv_ondato_success-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.fv_ondato_success-logo {
    width: 200px;
    margin-bottom: 1rem;
}

.fv_ondato_success-title {
    font-size: 2.5rem;
    color: #2d3748;
    margin-bottom: 0.5rem;
}

.fv_ondato_success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.fv_ondato_success-icon {
    font-size: 4rem;
    color: #38a169;
    margin-bottom: 1rem;
}

.fv_ondato_success-message {
    font-size: 1.25rem;
    color: #4a5568;
    margin-bottom: 2rem;
}

.fv_ondato_success-button {
    background-color: #3182ce;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 30px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
        background-color: #2b6cb0;
        transform: translateY(-2px);
    }
}

@media (max-width: 600px) {
    .fv_ondato_success-container {
        padding: 1rem;
    }

    .fv_ondato_success-title {
        font-size: 2rem;
    }

    .fv_ondato_success-message {
        font-size: 1rem;
    }
}