.detail-page-modal-container {
  position: fixed;
  z-index: 12;
  width: 97.5vw;
  right: -100vw;
  overflow: hidden;
  padding: 0;
  transition: right 0.8s ease-out;
  box-shadow: -4px 5px 8px 6px #2624246e;
  border-top-left-radius: 15px;
}

@keyframes slideIn {
  0% {
    width: 0vw
  }

  100% {
    width: 97.5vw;
  }
}

.detail-page-modal-container {
  animation: slideIn 0.8s ease-in-out 0s forwards;
}


.detail-page-modal-show {
  right: -13px;
}

@media only screen and (max-width: 1050px) {
  .detail-page-modal-container {
    width: calc(100% - 25px);
    top: 55px;
    z-index: 12 !important;
    /* width: 100%; */
    right: calc(-1 * (100% + 50px));
    box-shadow: none;
  }

  .detail-page-modal-show {
    right: 0px;
  }
}


.inside_pane_modal {
  background: #00000075;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}