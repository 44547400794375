.layout-action-menu .dropdown-item::before {
    content: "•";
    margin-right: 5px;

}

.layout-action-menu .dropdown-item {

    display: flex !important;
    align-items: center;
    justify-content: start !important;
}