.fv_ubo_button {
    display: block;
    padding: 10px 20px;
    margin-top: 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: background 0.3s;
}

.fv_ubo_button:hover {
    background: #0056b3;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}