.feedback_table_header {
    width: 16.6%;
    text-align: center;
}

.feedback_table_body {
    text-align: center;
}

.feedback_dot {
    height: 3px;
    width: 3px;
    border-radius: 60%;
    background-color: black;
}