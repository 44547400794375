.rthfc .rt-thead.-filters {
  z-index: 2;
  padding: 4px 5px 0 5px !important;

}
.rt-table, .ReactTable {
  border-radius: 20px 20px 0px 0px;
}

.rthfc .rt-th {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  background-color: #DFDFDF !important;
}

.rthfc .rt-td {
  background-color: #F1FAFC !important;
}

.rthfc .rt-thead.-headerGroups,
.rthfc .rt-thead.-header {
  z-index: 3;
  padding: 0px 5px 0px 5px !important;
  background-color: #FAFAFA !important;
}

.rthfc.-striped .rt-tr.-odd .rt-td {
  background-color: #fff !important;
}

.rt-th .rt-resizable-header {
  height: 36px;
}

.rt-resizable-header-content {
  background-color: #DFDFDF !important;
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 5px;
  height: 100%;
}

.rthfc.-highlight .rt-tr:hover .rt-td {
  background-color: #F1FAF6 !important;
}

.rthfc .-filters .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-th.rthfc-th-fixed-left-last,
.rthfc .rt-td.rthfc-td-fixed-left-last {
  border-right: solid 1px #ccc;
}

.rthfc .rt-th.rthfc-th-fixed-right-first,
.rthfc .rt-td.rthfc-td-fixed-right-first {
  border-left: solid 1px #ccc;
}

/*------------ Sticky position version: -sp ------------*/

.rthfc.-sp .rt-tbody {
  overflow: visible;
  flex: 1 0 auto;
  margin: 5px;
}

.rthfc.-sp .rt-thead {
  position: -webkit-sticky;
  position: sticky;
}

.rthfc.-sp .rt-thead.-headerGroups {
  border-bottom-color: #f2f2f2;
}

.rthfc.-sp .rt-tfoot {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  bottom: 0px;
}

.rthfc.-sp .rthfc-th-fixed,
.rthfc.-sp .rthfc-td-fixed {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.rthfc.-sp .rthfc-th-fixed {
  /* set z-index = 11 because rt-resizer has z-index = 10 */
  z-index: 11;
}

.rthfc.-sp .rthfc-th-fixed-left,
.rthfc.-sp .rthfc-td-fixed-left {
  left: 0;
}

.rthfc.-sp .rthfc-th-fixed-right,
.rthfc.-sp .rthfc-td-fixed-right {
  left: 0;
}

/*------------ scroll event version: -se ------------*/

.rthfc.-se .-header .rt-th.rthfc-th-fixed,
.rthfc.-se .-headerGroups .rt-th.rthfc-th-fixed,
.rthfc.-se .-filters .rt-th.rthfc-th-fixed,
.rthfc.-se .rt-td.rthfc-td-fixed {
  position: relative;
  z-index: 1;
}

.rthfc.-se .-header .rt-th.rthfc-th-fixed {
  /* set z-index = 11 because rt-resizer has z-index = 10 */
  z-index: 11;
}

.rt-th {
  background-color: #F1FAFC !important;
  justify-content: center;
  align-items: center;
}

.rt-td {
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  overflow: visible !important;
}

/* .rt-td div div {
  ====todo tooltip error ====
 width: 100%;
} */

.rt-tr {
  height: 40px;
}

.height-100 {
  height: 100%;
}
.larger-bullet {
  font-size: 1.2em;  
}

