.apply-individual-lite {
    // background: linear-gradient(to bottom right, #f3f4f6, #e2e8f0);
    background: #ffffffe6;
    border-radius: 15px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.apply-individual-lite::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    // background: url('path/to/your/aesthetic.svg') no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    animation: rotate 20s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.fv_indi-container {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    text-align: start;
    max-width: 600px;
    width: 90%;
    margin: 50px auto;
    position: relative;
    z-index: 1;
}

.fv_indi-title {
    font-size: 2rem;
    color: #1a202c;
    margin-bottom: 20px;
    font-weight: 600;
}

.fv_indi-list {
    list-style: none;
    padding: 0;
}

.fv_indi-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    color: #333;
}

.fv_indi-icon-container {
    width: 40px !important;
    min-width: 40px !important;
}

.fv_indi-icon {
    margin-right: 10px;
    color: #3a8dff; // A nice blue to give a professional feel
}

.fv_start_screen-button {
    background-color: #3a8dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
        background-color: #1c6fd9;
        transform: translateY(-2px);
    }
}

.bold-text {
    font-weight: bold;
}

.box-tab {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(198, 243, 255, 0.331);
}

.fvi_text_area {
    background-color: #F1FAFC;
    border-radius: 6px;
    border: none;
}

.fvi_text_area:hover,
.fvi_text_area:focus {
    background-color: #F1FAFC;
}

.fvi_text_area:disabled {
    background-color: #F1FAFC;
    color: rgb(69, 69, 69)
}