.user-name-input-field {
    background-color: #F1FAFC;
    border-radius: 14px;
    outline: none;
    min-height: 50px;
    font-size: 15px;
    color: #000;
}

.error-message {
    color : red
}