.signPad {
    width: 420px;
    max-width: 70vw;
    padding: 10px;
    border: 1px solid #ebebeb;
}

.signPad .inner-sign-pad {
    height: 230px;
    padding: 10px;
    border: 1px solid #ebebeb;
    height:500px;
}

.signPad .inner-sign-pad .canvas {
    height: 475px;
    width: 375px;
}

@media (max-width: 500px) {
    .signPad {
        max-width: 87vw;
        border: 1px solid #ebebeb;
    }

    .signPad .inner-sign-pad {
        padding: 0px;
        border: 1px solid #ebebeb;
        height:220px;
    }
    .signPad .inner-sign-pad .canvas {
        height: 100%;
        width: 100%;
    }
}

.fv_application_document-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 5px 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;


    &+.fv_application_document-group {
        margin-top: 30px;
    }
}


/* /// BusinessPlusKycStatus Table /// */

/* Example minimal styling, adapt to your project's needs */
.fv_bplus_operators_wrapper {
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    max-width: 1200px;
}

/* Title */
.fv_bplus_operators_title {
    border-top: 1px solid #ebebeb;
    padding-top: 15px;
    font-size: 1.25rem;
    font-weight: 600;
}

/* Loader / No data text */
.fv_bplus_operators_loader {
    min-height: 200px;
}

.fv_bplus_operators_noData {
    text-align: center;
    color: #888;
}

/* Table */
.fv_bplus_operators_table {
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;
}

.fv_bplus_operators_table_head {
    background-color: #fafafa;
}

.fv_bplus_operators_table_th {
    font-weight: 600;
    white-space: nowrap;
}

.fv_bplus_operators_table_td {
    vertical-align: middle;
}

.fv_bplus_operators_table_tr:hover {
    background-color: #fdfdfd;
}

/* Link & copy icon */
.fv_bplus_operators_link {
    text-decoration: none;
    color: #007bff;
}

.fv_bplus_operators_link:hover {
    text-decoration: underline;
}

.fv_bplus_operators_copyIcon {
    color: #555;
}

.fv_bplus_operators_copyIcon:hover {
    color: #000;
}