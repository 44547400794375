.select-screen {
    background: linear-gradient(to bottom right, #f3f4f6, #e2e8f0);    border-radius: 15px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.select-screen::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    // background: url('path/to/your/aesthetic.svg') no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    animation: rotate 20s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.fv_start_screen-container {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    text-align: center;
    max-width: 900px;
    width: 90%;
    margin: 50px auto;
    position: relative;
    z-index: 1;
}

/* Media query for mobile view */
@media (max-width: 400px) {
    .fv_start_screen-container {
        width: 100%;
        margin: 0px;
        padding: 2rem;
        border-radius: 0; 
    }
}

.fv_start_screen-logo {
    margin-bottom: 40px;
    animation: fadeIn 1s ease-in-out;
}

.fv_start_screen-title {
    font-size: 2rem;
    color: #1a202c;
    margin-bottom: 1rem;
}

.fv_start_screen-subtitle {
    font-size: 1.2rem;
    color: #4a5568;
    margin-bottom: 2rem;
}

.fv_start_screen-card {
    flex: 1 1 300px;
    height: auto;
    padding: 20px;
    border: none;
    border-radius: 15px;
    transition: transform 0.3s, box-shadow 0.3s;
    background: linear-gradient(to bottom right, #ffffff, #e2e8f0);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }
}

.fv_start_screen-card h2 {
    font-size: 1.5rem;
    margin: 10px;
    color: #2d3748;
}

.fv_start_screen-card-button {
    margin-top: 20px;
}

.fv_start_screen-button {
    background-color: #3a8dff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;

    &:hover {
        background-color: #1c6fd9;
        transform: translateY(-2px);
    }
}

.fv_start_screen-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.fv_start_screen-responsive-card {
    margin: 1rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moveUpDown {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

.fv_start_screen-animated-svg {
    animation: moveUpDown 3s ease-in-out infinite;
    margin-bottom: 20px;
}