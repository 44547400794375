.red-asterisk::after {
  content: "*";
  color: red;
  margin-left: 2px;
}


.dot {
  margin-right: 2px;
  margin-top: 3px;
  height: 11px;
  width: 11px;
  border-radius: 50%;
  display: inline-block;
}

.notification-img {
  box-sizing: border-box;
  border-radius: 40%;
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #7286EA;
}

.pie-dimensions {
  width: 180px;
  heigth: 180px;

}

/* =============== */

/* Style for the container of the horizontal timeline */
.hori-timeline {
  overflow: hidden;
}

.scroll-progress {
  max-height: 300px;
  overflow-y: scroll;
}

.scroll-progress::-webkit-scrollbar {
  display: none;
}

/* Style for individual timeline items */
.eventss {
  display: flex;
  overflow-x: auto;
  padding-bottom: 20px;
  /* Adjust as needed */
}

.events-list {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
  min-width: 250px;
  /* Set a minimum width for each item */
  flex: 0 0 auto;
  background-color: #fff9f9;
  position: relative;
}

.events-list::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -50%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
  /* Change the timeline connector color */
  z-index: -1;
}

.events-list:first-child::before {
  display: none;
  /* Hide connector before the first item */
}

.events-date {
  margin-right: 15px;
}

/* Style for the content within the timeline item */
.events-list h5 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.events-list p {
  margin-bottom: 10px;
  font-size: 14px;
  color: #555;
}

.events-list a {
  color: #007bff;
  text-decoration: none;
}

.events-list a:hover {
  text-decoration: underline;
}

/* Styles for FontAwesome icons */
.text-success {
  color: green;
}

.text-muted {
  color: #aaa;
}