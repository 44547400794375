.selectfield {
  font-size: 12px;
  width: 100%;
  color: #52585f;
  padding: 5px 0px 5px 0px;
  margin: 0 6px 0 0;
  position: relative;
  /* min-width: 230px; */
}

.selectfield_multiple {
  font-size: 12px;
  width: 100%;
  color: #52585f;
  padding: 0px;
  margin: 0 6px 0 0;
  position: relative;
}

.selectfield__wrapper {
  width: 100%;
  min-width: 80px;
  min-height: 36px;
  align-items: center;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

[class$="MenuPortal"] {
  z-index: 9999 !important;
}


.css-14h4o58-menu {
  position: fixed !important;
}

.css-1nmdiq5-menu {
  position: absolute !important;
  z-index: 999;
}

.css-dqlu61-control {
  max-height: "max-content !important";
}

.css-4i4isv-IndicatorsContainer {
  /* max-height: 24px; */
}

.grouped-input-group {
  background-color: transparent !important;
  border-right: 1px solid rgb(206, 212, 218) !important;
  margin-right: 1px;
  height: 100% !important;
  max-width: 50%;
  font-size: 14px !important;
}

.grouped-input-select-group {
  background-color: transparent !important;
  border-right: 1px solid rgb(206, 212, 218) !important;
  margin-right: 1px;
  height: 34px !important;
  font-weight: 600 !important;
  max-width: 50%;
  padding: 0 !important;
  font-size: 16px !important;
}

.css-346i55-singleValue {
  position: absolute;
}

.css-1ken5dz-Input {
  padding-left: 10px !important;
  width: 50%;
}

.css-346i55-singleValue {
  grid-area: 1/1/1/1 !important;
  padding-left: 10px !important;
  padding-top: 3px !important;
  margin-left: 0 !important;
}

.selectfield__wrapper>button {
  border: none;
  padding: 2px 10px;
  margin-left: 4px;
}

.selectfield__container {
  width: 100%;
  /* padding: 10px 0; */
  /* background-color: rgb(30, 72, 109); */
}

.selectfield__select {
  color: #52585f;
  border: none;
  font-size: 14px;
  /* min-height: 24px; */
}

.grouped_selectfield__select {
  color: #52585f;
  border: none;
  font-size: 14px;
  /* height: 24px; */

}

.selectfield__select:focus {
  color: #52585f;
  border: none;
  outline: none;
  max-height: 300px;
}

.selectfield__label,
.selectfield__clear {
  font-weight: normal;
  position: absolute;
  left: 8px;
  top: 0px;
  padding-top: 4px;
  font-size: 14px;
  color: rgba(121, 121, 121, 0.694);
  cursor: text;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in,
    color 200ms ease-in;
  background-color: transparent;
  /* line-height: 12.8px; */
}

.selectfield__clear {
  padding: 0;
  top: 8px;
}

.selectfield__clear>i {
  position: relative;
  left: 8px;
  background-color: white;
}

.selectfield__notempty_input,
.selectfield__select:focus~.selectfield__label {
  top: -6.5px;
  border: none;
  padding: 0;
  left: 6.5px;
  font-size: 10px;
  color: #52585fcb;
}

.selectfield_multi__notempty_input,
.selectfield__select:focus~.selectfield__label {
  font-size: 0px;
  background-color: transparent;
}


.selectfield__innercontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #F1FAFC;
  border-radius: 14px;
  min-height: 50px;
  border: 0px solid #C6F3FF
}

.selectfield_inline__innercontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 6px;

}

.selectfield__leftComponent,
.selectfield__rightComponent {
  width: 30px;
  max-width: 30px;
}

.selectfield__clear {
  left: unset;
  right: 15px;
  cursor: pointer;
}

/* .css-1s5hiak-control{
  width: 100%;
} */
.component_pointer {
  cursor: pointer;
}

.error_label,
.selectfield__error,
.selectfield__info,
.selectfield__message,
.selectfield__suggestion {
  position: relative;
  left: 8px;
}

.selectfield__error {
  font-style: normal !important;
  font-size: 12.8px !important;
  color: rgb(236, 115, 75) !important;
}

.selectfield__message {
  font-style: normal;
  font-size: 12px;
  color: green;
}

.selectfield__info {
  font-style: normal;
  font-size: 12px;
  color: #404040;
}



.error_label {
  font-style: normal;
  font-size: 12.8px;
  padding-top: 10px !important;
  color: rgb(236, 115, 75);
}


.error__message {
  font-style: normal;
  font-size: 12.8px;
  color: rgb(236, 115, 75);
}


.selectfield__suggestion {
  bottom: 9.6px;
}

.change-data {
  background-color: #fff;
  border: 1px double #eee;
  color: rgb(41, 146, 208);
  padding: 6px 10px 6px 10px;
  /* display: flex; */
  justify-content: center;
  min-width: 100%;
  min-height: 8px;
  cursor: pointer;
  /* box-shadow: 3px 3px -5px #999999; */
}

.fetching {
  display: flex;
  justify-content: center;
  font-size: 12px;
  padding: 20px 0;
  font-weight: 200;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inline-select-field {
  height: 14px;
}

.selectfield__plain>div {
  border-color: transparent;
}

.selectfield__plain:hover>div {
  border-color: #ced4da;
}

.group-label-container {
  position: relative;
  display: inline-block;
}

.group-label-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-label-tooltip {
  visibility: hidden;
  width: calc(100% -20px);
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

.group-label-container:hover .group-label-tooltip {
  visibility: visible;
  opacity: 1;
}
