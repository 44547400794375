.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: red;
}

.row {
  margin: 0px;
}

.otpInput {
  margin: 8px 0px 0x 0px !important;
  display: flex;
}


.otp-container {
  height: 40px;
  width: 45px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-left: 13px;
  text-align: "center";
  font-size: 24px;
}

.otp-container:focus-visible {
  border: 2px solid #8eb6e5 !important;
  outline: 0px;
}

/* Carousel.css */

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
