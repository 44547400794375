/* General Styling */
.fv_phone-otp-validation {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.fv_phone-validation-container {
    display: flex;
    align-items: flex-start;
}

.fv_send-otp-button {
    margin-left: 10px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal Styling */
.fv_modal-content {
    border-radius: 10px;
    padding: 20px;
}

.fv_modal-header {
    font-weight: bold;
    border-bottom: none;
}

.fv_modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 0;
}

.fv_modal-body {
    text-align: center;
    font-size: 16px;
    color: #555;
}

.fv_otp-input-container input {
    width: 50px;
    height: 50px;
    margin: 0 5px;
    text-align: center;
    font-size: 24px !important;
    border: 1px solid #c6f3ff;
    border-radius: 8px;
    background-color: #f1faff;
    outline: none;
    padding-left: 4px !important;
}

/* Error Message */
.fv_error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
}