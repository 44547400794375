/* ___modal.css */

.___modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 40px;
  bottom: 40px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.___modal-content {
  position: relative;
  // top: 70px;
  max-height: 100vh;
  bottom: 40px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 80%;
}

.___modal-close {
  position: absolute;
  top: 6px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #999;
  z-index: 18;
}

.___modal-close:hover {
  color: #333;
}

.___modal-title {
  text-align: start;
  position: sticky;
  top: 0;
  z-index: 17;
  /* background-color: blue; */
}

.___modal-title h2 {
  padding: 20px;
  font-size: 24px;
}

.___modal-body {
  padding: 0 20px 0px 20px;

}

.___modal-body-scroll {
  /* min-width: 300px; */
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.___modal-body-prevent {
  /* min-width: 300px; */
  height: calc(100vh - 120px) !important;
}

.___modal-footer {
  text-align: center;
  position: sticky;
  bottom: 0;
  padding: 10px;
  /* background-color: blue; */
  z-index: 1;
  display: flex;
  height: 60px !important;
  background-color: #fff;
  justify-content: end;
  border-radius: 0px 0px 25px 25px;
}

.___modal-footer button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.___modal-footer button:hover {
  background-color: #0056b3;
}

.___modal-top {
  padding-top: 0px;
  top : 70px;
  align-items: flex-start;
}

.___modal-centered {
  align-items: center;
}

.___modal-bottom {
  align-items: flex-end;
}

/* Size Classes */
.___modal-small {
  max-width: 400px;
  max-height: 400px;
}

.___modal-large {
  width: 80%;
}

.___modal-md {
  width: 40%;
  max-width: 700px;
}

.___modal-medium {
  width: 60%;
  max-width: 1000px;

}

.___modal-lessmedium {
  width: 30%;
  max-width: 1000px;
  min-width: 300px;
}

.___modal-full-screen {
  min-width: 100vw;
  height: 100vh;
}

.___modal-95vw {
  min-width: calc(100vw - 250px);
}

@media (max-width: 768px) {

  .___modal-medium {
    width: 80%;
    max-width: 100%;

  }
}

@keyframes slideInModal {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.___modal-opensFromRight {
  animation: slideInModal 0.8s ease-in-out 0s forwards;
  justify-content: flex-end !important;
  background-color: rgba(0, 0, 0, 0.2);
}

@keyframes slideOutModal {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}

.___modal-closeFromRight {
  animation: slideOutModal 0.8s ease-in-out 0s forwards;
  justify-content: flex-end !important;
  background-color: rgba(0, 0, 0, 0.2);
}