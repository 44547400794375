.mouseHoverFilter {
  border: 0px solid rgb(255, 255, 255);
  border-width: 1px 0px;
  background-color: white;
}

.mouseHoverFilter:hover {
  color: white !important;
  background-color: #2c66dd;
  /* border-color: rgb(206, 212, 218); */
}

.dropdown-hover:hover {
  color: #495057;
  background-color: #f8f9fa;
}

.mouseHoverFilter .desc{
  color: #8f979e;
}

.mouseHoverFilter:hover> .desc {
  color: rgb(238, 237, 237);
  /* background-color: #2C66DD; */
  /* border-color: rgb(206, 212, 218); */
}

#buttonCloseFilter {
  /* display: none; */

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
    background-color: #ebeced;
      border: 1px solid #ced4da;
      border-left: 0px !important;
        opacity: 0.75;

        margin: 0px 10px 0px 0px;
        border-radius: 0px 5px 5px 0px;
  /* background-color rgb(0, 0, 0); */
  /* transition: opacity 0.2s ease-in-out; */
}

.filterField:hover #buttonCloseFilter,
.filterField_parent:hover #buttonCloseFilter,
.filterField_checkbox:hover #buttonCloseFilter {
  /* display: flex; */
  opacity: 0.75;
  background-color: #ebeced;
  /* border: 1px solid #ced4da; */
}

.filterField_parent {
  display: flex;
  width: 250px;
  z-index: 4;
}

.filterField_checkbox {
  display: flex;
}

.filterField {
  width: inherit;
}

.dropdown-transparent {
  background-color: transparent !important;
  color: black !important;
  font-weight: 500 !important;
  padding: 0 !important;
  border: 0 !important;
  z-index: 3 !important;
}

.dropdown-menus:hover {
  z-index: 3 !important;
}

.dropdown-menus {
  z-index: 3 !important;
}

.dropzone-preview {
  position: absolute;
  top: 65px;
  padding-left: 20px;
}

.dz-height {
  min-height: 80px;
}

.dz-files-height {
  min-height: 100px;
  max-width: 110px;
  background-color: #495057;
}

.dz-file-name {
  visibility: visible;
}

.dz-file-fullname {
  visibility: hidden;
  position: absolute;
  background-color: #ced4da;
  color: #000;
  left: -10px;
  z-index: 3;
}

.dz-file-name:hover~.dz-file-fullname {
  visibility: visible;
}

.dz-file-name:hover .dz-file-size {
  margin-top: 10px;
}

.filterField_input {
  display: flex;
}

.filterField_input:hover #buttonCloseFilter {
  opacity: 0.75;
  background-color: #ebeced;
  border: 1px solid #ced4da;
}

.filter-open-menu{
      position: absolute;
      z-index: 4;
      overflow-y: scroll;
      overflow-x: hidden;
      max-height: 40vh;
      height: fit-content;
      width: fit-content;
      border-left: 1px solid rgb(206, 212, 218);
      border-right: 1px solid rgb(206, 212, 218);
      border-bottom: 1px solid rgb(206, 212, 218);
      font-weight: 400;
      left: 0;
      top:  29px;
}

.filter-open-menu-selected {
  position: absolute;
  z-index: 4;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 40vh;
  height: fit-content;
  width: max-content;
  max-width: 80vw;
  border-left: 1px solid rgb(206, 212, 218);
  border-right: 1px solid rgb(206, 212, 218);
  border-bottom: 1px solid rgb(206, 212, 218);
  font-weight: 400;
  /* right: 0; */
  left: 0;
  top: 29px;
}