.step-nav {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.step-item {
  flex: 1;
  text-align: center;
  position: relative;
}

.step-item:not(:last-child)::after {
  content: '';
  width: 100%;
  height: 3px;
  background: #ddd;
  position: absolute;
  top: 23px;
  left: 50%;
  z-index: 0;
}

.step-item:first-child::before {
  content: none;
}

.step-link {
  display: block;
  padding: 10px 0px; 
  border: 0;
  background: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.step-link.completed .step-indicator {
  color: green;
}

.step-link.active .step-indicator,
.step-link.completed .step-indicator {
  border-color: #1678AE;
}

.step-item.completed .step-indicator {
  border-color: #1678AE;
}

.step-indicator {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 20px;
  border: 8px solid #ddd;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}

.step-number {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.fa-check {
  color: #fff;
}

.step-label {
  display: block;
  margin-top: 5px;
  font-size: 0.85rem;
}

// .step-item.active:not(:last-child)::after,
.step-item.completed:not(:last-child)::after {
  background: #1678AE;
}


@media(max-width: 500px) {
    .step-nav {
        display: none !important;
    }
}