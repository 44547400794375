.form-content {
    width: 1000px;
    margin:30px;
    // height: 600px;
    overflow-y: scroll;
    padding: 25px;
    padding-top: 20px;
    border: 1px solid #D6D6D6;
    font-size: 14px;
    border-radius: 20px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
    min-height:calc(100vh - 60px);
}

@media (min-width: 992px) {
    .form-content {
        width: 75%;
    }
}
@media (max-width: 500px) {
    .form-content {
        margin:0px;
        width: 100%;
        border: 0px;
        border-radius: 0px;
        height:100vh;
    }
}

.form-content::-webkit-scrollbar {
    display: none;
}

.form-check-label {
    color: #333;
    margin-top: 3px;
    font-size: 14px;
    font-weight: bold;
}

.form-check-input[type="checkbox"] {
    border-color: #000;
    height: 20px;
    width: 20px;
}

.form-check-input[type="radio"] {
    border-color: #000;
    height: 20px;
    width: 20px;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled,
.form-check-input:disabled~.form-check-label {
    color: #000 !important;
    opacity: 1 !important;

}

.form-check-label {
    margin-left: 5px;
}


.user-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 20px;
    border-radius: 10px;
    font-family: 'Open Sans', sans-serif;

    .user-note-title {
        margin: 10px;
        font-size: 24px;
        font-weight: 500;
    }

    .user-note-content {
        width: 100%;
        margin-bottom: 20px;

        .user-note-item {
            display: flex;
            align-items: center;
            background: #fff;
            margin: 10px 0;
            padding: 10px;
            border-radius: 5px;

            .icon {
                color: #1678AE;
                margin-right: 10px;
                font-size: 20px; // Adjust the size of the icon as needed
            }

            span {
                font-size: 16px;
            }
        }
    }

    .user-note-button {
        background-color: #1678AE;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        padding: 8px 32px;
        border-radius: 20px;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: darken(#1678AE, 10%);
        }
    }
}

.language-picker-form {
    position: absolute;
    top: 0;
    right: 0;
}