.fv_pre_ubo-container {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.fv_pre_ubo-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.fv_pre_ubo-input-group-phone {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

.fv_pre_ubo-input-group {
    margin-bottom: 0px;
}

.fv_pre_ubo-label {
    font-size: 16px;
    font-weight: 500;
    color: #495057;
    margin-bottom: 5px;
    display: block;
}

.fv_pre_ubo-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 16px;
    color: #495057;
    outline: none;
    transition: border-color 0.2s;
}

.fv_pre_ubo-input:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.fv_pre_ubo-error {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 5px;
}

.otp-verified-icon {
    color: #28a745;
    font-size: 20px;
    margin-left: 10px;
}

.fv_pre_ubo-button {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: #1678ae;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fv_pre_ubo-button:hover {
    background-color: #125b84;
}



.fv_modal-content {
    max-width: 400px;
    margin: auto;
}

.fv_modal-body {
    padding: 20px;
    text-align: center;
}

.fv_otp-input-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.fv_otp-input-container input {
    font-size: 18px;
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #c6f3ff;
    border-radius: 10px;
    margin: 0 5px;
}

.fv_error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 10px;
}



.fv_btn-secondary {
    background-color: #f1faff;
    color: #007bff;
    border: 1px solid #c6f3ff;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 16px;
}

.selectfield__info {
    font-size: 14px;
    color: #6c757d;
    margin-top: 5px;
}

.selectfield__error,
.selectfield__message {
    font-size: 14px;
    margin-top: 5px;
}

.selectfield__error {
    color: #e74c3c;
}

.selectfield__message {
    color: #28a745;
}

.user-name-input-field {
    width: 100%;
    padding: 10px;
    border: 0px solid #ced4da;
    border-radius: 8px;
    font-size: 16px;
    color: #495057;
    outline: none;
    transition: border-color 0.2s;
}

.user-name-input-field:focus {
    border: none;
    box-shadow:none;
}