.inputfield {
  /* margin: 2px 4px; */
  position: relative;
  width: 100%;
  padding: 2px 12px;
  height: max-content;
  border: none;
}

.inputfield__container {
  /* background-color: #fff; */
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}

.icon {
  align-self: center;
  display: flex;
  margin-top: 6.4px;
}

.edit_cross {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 15px;
}

.cross-map {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 0px;
}

.edit_back {
  /* position: absolute; */
  z-index: 1000;
  padding: 50px 0 0 0;
  /* right: 10px;
  top: 47px */
}

.border-left-gray {
  border-left: "2px solid #ced4da";
}

.inputfield__input {
  height: 22px;
  padding-left: 5px;
  width: 100%;
  border: none;
  border-radius: 4.8px;
  font-family: inherit;
  font-size: 14px;
  outline: none;
  background: none;
}

.inputfield__label,
.inputfield__clear {
  font-weight: normal;
  position: absolute;
  left: 4px;
  top: 3px;
  font-size: 12px;
  color: rgba(121, 121, 121, 0.694);
  cursor: text;
  background-color: white;
}

.inputfield__eye {
  font-weight: normal;
  position: absolute;
  left: 9.6px;
  top: 6px;
  font-size: 12.8px;
  color: rgba(121, 121, 121, 0.694);
  cursor: text;
  background-color: white;
}

.inputfield__label {
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in,
    color 200ms ease-in;
}

.inputfield:hover .inputfield__clear {
  opacity: 1;
  background-color: rgba(128, 128, 128, 0.637);
  position: absolute;
  padding: 0 1% 0 1%;
  padding-top: 11px;
  height: 130%;
  top: -15%;
  right: -4.5px;
  border-radius: 0 6px 6px 0;
}

.inputfield__clear>i {
  position: relative;
  left: 0px;
  color: hsl(0, 0%, 80%);
}

.inputfield__eye>i {
  opacity: 1;
  position: relative;
  left: 0px;
  color: hsl(0, 0%, 80%);
}

.inputfield__input:focus~.inputfield__label,
.inputfield__input:not(:placeholder-shown).inputfield__input:not(:focus)~.inputfield__label {
  font-weight: normal;
  top: -12px;
  font-size: 10px;
  color: #52585fcb;
  border: none;
  padding: 0;
  max-height: fit-content;
  left: 8px
}

.inputfield__innercontainer-plain {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 6px;
  max-height: 36px;
}

.inputfield__innercontainer {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ced4da;
  border-radius: 6px;
  font-size: 18px;
  background-color: #F1FAFC;
  border-radius: 14px;
  min-height: 50px;
  border: 0px solid #C6F3FF
}

.inputfield__innercontainer_noborder {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #ced4da; */
  /* border-radius: 6px; */
  max-height: 36px;
}

.inputfield__leftComponent,
.inputfield__rightComponent {
  width: max-content;
  object-fit: contain;
  height: 100%;
  /* max-width: 22px; */
  max-height: 100%;
}

.inputfield__clear {
  left: unset;
  right: 7px;
  cursor: pointer;
  padding: 0;
  top: 3.2px;
  opacity: 0;
}

.inputfield__eye {
  left: unset;
  right: 7px;
  cursor: pointer;
  padding: 0;
  top: 3.2px;
  opacity: 1;
  background-color: rgba(255, 255, 255);
  position: absolute;
  padding: 0 1%;
  padding-top: 7px;
  height: 100%;
  top: -1%;
  right: 0%;
}

.component_pointer {
  cursor: pointer;
  width: max-content;
  max-width: max-content;
}

.inputfield__error,
.inputfield__suggestion {
  position: relative;
  left: 8px;
}

.inputfield__error {
  font-style: normal;
  font-size: 12.8px;
  color: rgb(236, 115, 75);
}

.inputfield__desc{
  position: relative;
  left: 8px;
}

.inputfield__desc.left-0 {
  position: relative;
  left: 0px;
}

.inputfield__desc {
  font-style: italic;
  font-size: 10.8px;
  color: #808080;
}

.inputfield__suggestion {
  bottom: 6.4px;
}

.__backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding-top: 100px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 15;
  overflow-x: scroll;
  overflow-y: scroll;
  margin-right: 40px;

}

.__modal {
  background-color: #fff;
  /* height: 30%; */
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
  /* padding: 20px; */
  /* box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2); */
}


.__modal-filter {
  background-color: #fff;
  height: max-content;
  /* min-height: 100px */
  height: 90%;
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow-y: scroll;
  padding: 50px 20px 20px 20px;
  /* padding: 20px; */
  /* box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2); */
}

.__modal-close-btn {
  position: fixed !important;
  right: 20px;
  top: 100px;
  border-radius: 50% !important;
  height: 40px;
  width: 40px;
  padding: 0px !important;
  z-index: 13;
}

.__dz-close-btn {
  position: absolute !important;
  right: -10px;
  top: -10px;
  border-radius: 50% !important;
  height: 24px;
  width: 24px;
  padding: 0px !important;
}

input:-internal-autofill-selected {
  background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.border-none>div {
  border-color: transparent;
}

.inputfield__plain {
  border-color: transparent !important;
}

/* .inputfield__plain:hover {
  border-color: #ced4da;
} */

.inputfield__innerInline {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
}

.autocomplete-item:hover {
  background-color: #eee;
}