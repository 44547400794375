.scroll-independent {
  min-height: "97%" !important;
  z-index: "900" !important;
  max-height: "97%" !important;
  overflow-y: "scroll" !important;
  position: "fixed" !important;
}

.left-scroll-independent {
  min-height: "87%";
  z-index: "1001";
  max-height: "87%";
  overflow-x: "scroll";
  position: "sticky";
  padding: 0;
}


.border-left-gray {
  border-left: "2px solid #ced4da" !important;
}

.bg-primary {
  background-color: #1678AE;
}

.react-confirm-alert-overlay {
  z-index: 10000000;
  background: rgba(0, 0, 0, 0.5);
}


.color-fff {
  color: #fff;
}

.cell-1 {
  border-collapse: separate;
  border-spacing: 0 4em;
  background: #ffffff;
  border-bottom: 5px solid transparent;
  /*background-color: gold;*/
  background-clip: padding-box;
  cursor: pointer;
}

.table-elipse {
  cursor: pointer;
}

#demo {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s 0.1s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.row-child {
  background-color: #000;
  color: #fff;
}

tr.collapse.in {
  display: table-row;
}


// input[type='checkbox'] {
//   height: 20px;
//   width: 20px;
// }

/* not active */
.nav-pills .pill-1 .nav-link:not(.active) {
  background-color: rgb(4, 129, 255);
  color: white;
}

.nav-pills .pill-2 .nav-link:not(.active) {
  background-color: rgb(52, 58, 64);
  color: white;
}

// .nav-pills .nav-link.active {
// background-color: rgb(241, 180, 76) !important;
// color: rgb(52, 58, 64) !important;
// }
.client-table .selectfield__wrapper {
  margin-top: 0px;
  margin-bottom: 0px;
}