.selectOptionWrapper{
    width: 100%;
    display: flex; flex-direction: column; justify-content: center;
    .labelWrapper{
        .label{
            margin: 10px 0px;
            font-family: 'Open Sans', sans-serif;            
            // font-family: 'Urbanist';
            font-size: 15px;
            font-weight: 400;
        }
    }
    .optionsWrapper{
  
       
        .option{
            cursor: pointer;
            border: 1px solid #C4C4C4;
            width: 50%;
            min-width: 350px;
            padding: 8px 20px;
            background-color: #FFFFFF;
            margin: 6px 0px;
            border-radius: 10px;
            .optionTickImage{
                opacity: 0;      
            }
            
            &.selected{
                border: 1px solid #F08180;
                .optionTickImage{
                    opacity: 1;
                    color: #F08180;

                }
                .optionText{
                    color:#F08180
                }
            }
            
        }
    }

}
