.fv_doc_field_file_link {
    cursor: pointer;
    color: #1678AE;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.fv_doc_field_icon {
    margin-right: 8px;
}

.fv_doc_field_modal_body {
    position: relative;
    max-height: 80vh; // Increase the maximum height of the modal body
    max-width: 80vw; // Increase the maximum height of the modal body
    overflow-y: auto; // Add scrollbars if the content exceeds the height

}

.fv_doc_field_download_btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.modal-header {

    border-bottom: none;
}

.upload-option {
    padding: 15px;
}

.upload-option-offline {
    padding: 15px;
    border-left: 1px solid #eaeaea;
}

.upload-title {
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 15px;
}

.icon-wrapper {
    width: 40px;
    text-align: center;
    font-size: 1.5em;
    margin-right: 10px;
    color: #555;
}

.upload-option ul {
    padding-left: 0;
}

.upload-option li {
    font-size: 1.1em;
}

.upload-option button {
    font-size: 1.1em;
    padding: 10px;
}

.no-default-p p {
    margin: 0 !important;
    padding: 0 !important;
}